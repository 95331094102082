
<template>
    <div class="left-space-desktop"></div>
    <div class="page-content w-100">
        <div class="row mt-2 ">
            <div class="col-sm-12 usersStyle">
                <div class="row mt-2">
                    <h4 class="RegisterPatientText">Registered patients</h4>
                </div>
                <div class="row" style="display: flex;margin: 10px; cursor: pointer;">
                    <div class="col-lg-3 col-md-3 col-6 mt-3" 
                        @click="openMobileRecord(userRecordsData)">
                        <div class="image-fix">
                            <div>
                    <div class="text-center name-fix">{{ userRecordsData.firstName }}</div>
                    <img class="img-women icon" src="@/assets/images/Male.png" v-if="userRecordsData.gender === 'Male'" />
                    <img class="img-women icon" src="@/assets/images/Female.png" v-else-if="userRecordsData.gender === 'Female'" />
                    <img class="img-women icon" src="@/assets/images/nogender.png" v-else-if="userRecordsData.gender === 'Others'">
                  </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6 mt-3" v-for="(value) in familyRecords" :key="value.id"
                        @click="openFamilyRecords(value)">
                        <div class="image-fix">
                            <div>
                    <div class="text-center name-fix">{{ value.firstName }}</div>
                    <img class="img-women icon" src="@/assets/images/Male.png" v-if="value.gender === 'Male'" />
                     <img class="img-women icon" src="@/assets/images/Female.png" v-else-if="value.gender === 'Female'" />
                    <img class="img-women icon" src="@/assets/images/nogender.png" v-else-if="value.gender === 'Others'">
                  </div>
                        </div>
                    </div> 
                    <div class="col-lg-3 col-md-3 col-6 mt-3" v-if="!closeAddPatient">
                        <div class="image-fix">
                            <div class="text-center name-fix">Add family member</div>
                            <img class="img-women icon" src="@/assets/images/plus-add.png" @click="addPatient()" />
                        </div>
                    </div>
                </div>
                     <div class="mt-3 mb-2" >
                        Note: <span >Select a person to view his/her medical records.</span> 
                    </div>
                <div class="hr-border"></div>

                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

                <div class="row">
                    <div class="col-12">
                        <div class="mt-3" v-if="selectMobileRecord">
                            <h3 class="medical-record">Medical records of {{ selectMobileRecord.firstName }}</h3>
                            <h3 class="medical-record">RxIx ID : {{ selectMobileRecord.id }}-{{ 1 }}</h3>
                            <patients :upMobileRecord="upMobileRecord" :key="isLoading"></patients>
                        </div>
                        <div class="mt-3" v-if="isNewUser">
                            <h3 class="medical-record">Medical records of {{ newUserData.firstName }}</h3>
                            <h3 class="medical-record">RxIx ID : {{ newUserData.id }}-{{ 1 }}</h3>
                            <patients :upMobileRecord="newUserData" :key="isLoading"></patients>
                        </div>
                         <div class="mt-3" v-if="selectFamilyRecord">
                            <h3 class="medical-record">Medical records of {{ selectFamilyRecord.firstName }}</h3>
                            <h3 class="medical-record">RxIx ID : {{ selectFamilyRecord.users.id }}-{{ 1+selectFamilyRecord.memberCount }}</h3>
                            <families :upFamily="upFamily"  :key="isLoading + upFamily.id" ></families>
                        </div>
                    </div>
                </div>
                <div class="row mb-3" v-if="openAddConsultation">
                    <div class="col-12 mt-2 horizondalLine">
                        <div class="hr-border"></div>
                    </div>
                </div>
               
                <div v-if="openAddPatient" class="mx-3">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="form-group ">
                                <label for="" class="mt-3">First name<span class="star">*</span></label>
                                <input type="text" class="form-control" v-model="firstName" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="" class="mt-3">Last name<span class="star">*</span></label>
                                <input type="text" class="form-control" v-model="lastName" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="" class="mt-3">Age in years<span class="star">*</span></label>
                                <input type="number" class="form-control" v-model="age" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="" class="mt-3">Gender<span class="star">*</span></label>
                                <div>
                                    <select class="form-control" name="gender" id="gender" v-model="selectedGender" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <label for="" class="mt-3">Relationship with user<span class="star">*</span></label>
                        <div class="col-6">
                            <select class="form-control" v-model="relation" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                                <option value="1">Select</option>
                                <option value="Father">Father</option>
                                <option value="Mother">Mother</option>
                                <option value="Son">Son</option>
                                <option value="Daughter">Daughter</option>
                                <option value="wife">Wife</option>
                                <option value="husband">Husband</option>

                            </select>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-5 mb-0 col-md-3 col-lg-3">
                            <button class="btn btn-blue-color text-white" :disabled="!isFormFamilyInvalid" @click="saveFamilyData()">Add</button>
                        </div>
                    </div>
                    <!-- <hr class="hr-border" /> -->
                </div>
                <div class="row my-2" v-if="selectMobileRecord || selectFamilyRecord ||isNewUser">
                    <button type="button" @click="patientSupportData()" :disabled="openAddPatient" class="btn btn-blue-color text-white addConsultBtn">Add consultation</button>
                </div>
                <!-- Add patient end -->
                <div v-if="patientSupport == true">
                    <div class="row mt-3">
                    <div class="col-md-12 col-12">
                        <h3 class="text-center">{{ date }}</h3>
                    </div>
                </div>
                <div v-for="(records,index) in upload_Record" :key="index">
                    <div class="row mt-2"  style="margin:5px;">
                        <div class="row mt-2">
                        <div class="col-sm-12">
                            <p class="mb-0">Case sheet<span class="star">*</span></p>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-md-12 col-12">
                            <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <form class="caseSheetTextAreas">
                                        <textarea class="doctorWritenPlace" v-model="records.caseSheet"></textarea>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                <label class="" for="date">Next consultation date<span class="star">*</span></label>
                </div>
          <div class="col-lg-3 col-sm-4 col-12">
            <div class="form-group">
                <!-- <label class="" for="date">Next consultation date</label> -->
                <input type="date" id="datepicker" :min="new Date().toISOString().split('T')[0]"
                    class="form-control" v-model="records.nextConsultation_date" />
            </div>
          </div>
        
        <div class="row my-2" >
            <div class="col-lg-5 col-sm-6 col-10">
              <label class="" for="date">Upload prescription</label>
              <input type="file" class="form-control " @change="fileSelect($event,index)">
            </div>
            <div class="col-lg-1 col-2 mt-4 my-2 ">
                <span class="text-align text-center plus-icon" style="font-size:30px"  >
                <font-awesome-icon  @click="isShowAddConsult()" :class="[!isConsultationDataValid ? 'disableCreateBtn' : '']" :icon='["fas", "plus-circle"]' />
            </span>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    </div>
        <div class="col-lg-7 col-md-7 col-sm-7 col-12 my-3">
         <button class="btn btn-blue-color text-white" :disabled="!isConsultationDataValid" @click="uploadData()">Close consultation</button>
        </div>
         </div>
            </div>
        </div>
    </div>
</template>
<script>
import patients from './clinician-others-patient.vue';
import families from './clinician-others-family.vue';
import moment from 'moment';
import axios from 'axios';
export default {
components: {
    patients,
    families
},
props: ['mobileData','isUserCreated','newPublic','selected'],
data() {
    return {
    userRecordsData: {},
    clinicianOthersPayload: {},
    gender: '',
    selectedGender:'',
    firstName: "",
    lastName: "",
    age: "",
    relation: "1",

    selectMobileRecord: null,
    selectUserRecord: null,
    selectFamilyRecord: null,
    openAddPatient: false,
    previousConsultation: false,
    openAddConsultation: false,
    closeAddPatient: false,
    upload_Record: [{
    caseSheet: "",
    nextConsultation_date: "",
    uploadImage: "",
    }],
    patientSupport: false,
    date: '',
    files: false,
    upFamily:{},
    uploadRecord:[],
    isLoading: false,
    familyRecords:[],
    upMobileRecord:{},
    isNewUser:null,
    newUserData:{},
    isSelected:{}
    };
},
created: function () {
    let clinicianothers = localStorage.getItem('clinician-others-id')
    this.clinicianOthersID = JSON.parse(clinicianothers);
    this.newUserData=this.newPublic
    this.isSelected = this.selected

    if(this.newUserData?.id){
        this.isNewUser=true
        this.openAddConsultation=true
    }
    this.getMobile = localStorage.getItem('mobile');
    this.getUserDetail();
    this.getClinicianOthers();
    this.getUplodRecord();
    this.getFamilyData();
    this.date = this.printDate();
    this.publicData = this.mobileData;
    if(this.isUserCreated){
        this.getUserDetail();
    }
},
computed:{
    isFormFamilyInvalid() {
    const nameRegex = /^[A-Za-z.\s]+$/;
    return (
    nameRegex.test(this.firstName) &&
    nameRegex.test(this.lastName) &&
    this.age <= 999 &&
    this.selectedGender !== "1" && 
    this.selectedGender .trim() !== ""&&
    this.relation !== "1" && 
    this.relation.trim() !== ""
    );
    },
    isConsultationDataValid() {
  if (this.upload_Record.length === 0) {
    return false; // No records to validate
  }
  for (let i = 0; i < this.upload_Record.length; i++) {
    const record = this.upload_Record[i];
    if (
      !record.caseSheet ||
      !record.nextConsultation_date 
    ) {
      return false; 
    }
  }
  return true; 
}
},
 methods: {
isShowAddConsult() {
      this.upload_Record.push({
        caseSheet: "",
        nextConsultation_date: null,
        uploadImage: null,
      });
    },
    addPatientDesk() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
    .get(`${BASE_API_URL}/add-patient`, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then(async (response) => {
        this.addPatientInfo = response.data;
        await this.addPatientInfo.map((data) => {
            if (data.mobile === this.getMobile) {
                this.publicData = data;
                this.dataMobile = data.mobile
            }
        })
        if (this.getMobile === this.dataMobile) {
            this.issubmit = true
            this.isNot = false
        }
        else {
            this.isNot = true
            this.issubmit = false
        }
    })
    },
    async getUserDetail() {
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
axios
    .get(`${BASE_API_URL}/public-user/${this.mobileData?.id || this.newPublic?.id}/one`)
    .then((response) => {
        if (response?.data) {
            this.userRecordsData = response.data;
        }
    })
    .catch(function (error) {
        console.log(error?.response);
    });
    },
    async getClinicianOthers() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    axios
    .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
    .then((response) => {
    
    if (response?.data) {
        this.clinicianOthersPayload = response.data
                }    
    }); 
},
changePublicFirstName(){
let name =this.firstName.toLowerCase()
return name.charAt(0).toUpperCase() + name.slice(1);
},
async saveFamilyData() {
    let familyPayload = {
    firstName: this.changePublicFirstName(),
    lastName: this.lastName.toUpperCase(),
    age: this.age,
    gender: this.selectedGender,
    relation: this.relation,
    users: this.userRecordsData,
    role: 'family',
    isDoctorAddUserFamily:true,
    clinician_others_id:this.clinicianOthersPayload
    }
    console.log(this.clinicianOthersPayload,"this.clinicianOthersPayload");
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
        .post(`${BASE_API_URL}/family-member/${this.userRecordsData.id}`, familyPayload, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    .then((res) => {
    if (res?.data) {
        this.getFamilyData();
        this. isAddNewMember=false
        this.firstName = "";
        this.lastName = "";
        this.age = "";
        this.selectedGender ="1";
        this.relation = "1";
        this.role = "";
        this.users = "";
        this.usersFamily = "";
        this.$swal("Added")
        this.openAddPatient=false
        this.closeAddPatient = false
            }
        })
        .catch(function (error) {
            console.log(error?.res);
        });
        },
openMobileRecord(value) {
    this.upMobileRecord = value
    this.isNewUser=false
    this.patientSupport =false
    this.selectMobileRecord = value
    this.selectFamilyRecord = false
    this.openAddConsultation = true
    this.openAddPatient = false
    this.closeAddPatient=false
},
openFamilyRecords(value) {
    this.upFamily = value;
    this.patientSupport =false
    this.selectUserRecord = false
    this.selectMobileRecord = false
    this.isNewUser=false
    this.openAddPatient = false
    this.selectFamilyRecord = value
    this.openAddConsultation = true
    this.closeAddPatient=false
},

addPatient() {
    this.patientSupport=false
    this.selectMobileRecord = false
    this.isNewUser=false
    this.closeAddPatient = true
    this.selectFamilyRecord = false
    this.selectUserRecord = false
    this.openAddPatient = true
    this.previousConsultation = true
    this.openAddConsultation=false
},

    patientSupportData() {
        this.patientSupport = true
    },
    printDate: function () {
        return new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).split(' ').join('-');
    },

    fileSelect(event,index) {
        this.upload_Record[index].uploadImage = event.target.files?.[0];
    },
    async compressImage(file) {
        if(file){
  return new Promise((resolve) => {
    const quality = 0.8; // Adjust quality as needed
    const maxWidth = 1000; // Adjust max width as needed
    const maxHeight = 1000; // Adjust max height as needed
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      ctx.drawImage(image, 0, 0, maxWidth, maxHeight);
      canvas.toBlob(
        (blob) => {
          const compressedFile = new File([blob], file.name, {
            type: 'image/jpeg', // Change the type as needed
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        },
        'image/jpeg', // Change the type as needed
        quality
      );
    };
    image.src = URL.createObjectURL(file);
  });
}
},
 async uploadData() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  for(let i=0;i<this.upload_Record.length; i++){
    const compressedImage = await this.compressImage(this.upload_Record[i].uploadImage);
    if(compressedImage){
  if (compressedImage.size < 22240) {
    this.$swal( "Image not clear. Please Upload image of better quality." );
    return;
  }
}
  this.date=moment(this.date).format("YYYY-MM-DD")
  let formData = new FormData();
  formData.append('doctor_name',this.clinicianOthersPayload.firstName);
  formData.append('clinician_others_id',this.clinicianOthersPayload.id);
  formData.append('caseSheet', this.upload_Record[i].caseSheet);
  formData.append('file', compressedImage);
  formData.append('isConsultation', 1);
  formData.append('nextConsultationDate',this.upload_Record[i].nextConsultation_date)
  formData.append('consultation_date',this.date );
if (this.selectMobileRecord  || this.newPublic?.id) {
      formData.append('user_id', this.selectMobileRecord?.id||this.newPublic?.id);
     formData.append('family_id', this.selectFamilyRecord?.id);
}
 else{
    formData.append('family_id', this.selectFamilyRecord?.id );
    formData.append('user_id', this.selectFamilyRecord?.users?.id);
  }
  if (this.isSelected) {
    if (this.isSelected.type === 'clinic') {
        formData.append('clinic_id', this.isSelected.id);
    } else if (this.isSelected.type === 'home') {
        formData.append('home_id', this.isSelected.id);
    }
}
  this.isLoading = true;
  axios
    .post(`${BASE_API_URL}/upload-records`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
if (response?.data) {
    this.$emit("clearAll", true);
      }
    })
}
    // .catch(function (error) {
    //   console.log(error.response);
    // });
},
getUplodRecord(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
    .get(`${BASE_API_URL}/upload-records`)
        .then((response) => {
            if (response?.data) {
                return response.data
            }
        })
},
 async getFamilyData(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let payload = {
        mobile: this.userRecordsData.mobile,
      };
  await  axios
        .post(`${BASE_API_URL}/public-user/get-sign`, payload)
        .then( (response) => { return response.data})        
  await axios
        .get(`${BASE_API_URL}/family-member`)
        .then((response) => {
            this.familyRecords=[];
        response.data?.map((data) => {
        if (data.users?.id === this.userRecordsData.id) {
        this.familyRecords.push(data)
    }
    })
         })
}
    },
}
</script>
<style>
.disableCreateBtn{
  color:  #dddddd !important;
  pointer-events: none;
  cursor: auto; 
}
.form-control {
    border-color: #34989f;
}
.star {
    color: red;
    font-size: 20px;
}
.addConsultBtn{
    width: fit-content;
    text-align: center;
    margin: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.RegisterPatientText {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.patient-record-img {
    height: 50px;
    width: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

p.text-color.text-side-align {
    text-align: center;
}

.icon-select-img {
    margin-left: 20px;
}

.image-fix {
    border: 3px solid #00979e;
    height: 100%;
    padding: 7px;
}

h3.medical-record {
    margin-left: 10px;
}

.text-center.name-fix {
    text-align: center !important;
    font-size: large;
    font-weight: bold;
}

img.img-camera.icon {
    float: right;
    width: 30px;
    margin: 5px;
}

img.img-men.icon {
    width: 50%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

img.img-women.icon {
    width: 50%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.nav.nav-tabs {
    padding-top: 15px;
}

.col-sm-12.usersStyle {
    margin: 0px 0px 0px 10px;
    background-color: #ffff;
    border-radius: 10px;
    padding-bottom: 15px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}

.col-sm-12.mx-auto.div-space {
    margin-bottom: 30px;
}

.left-space-desktop {
    margin-left: 20px;
}

.text-border {
    background-color: #00979e;
    height: 4px;
    margin-bottom: 20px;
}

.nav-link {
    display: block;
    padding: 9px;
    margin: 5px;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
    color: white;
    background-color: #00979e;
    border-color: #dee2e6 #dee2e6 #fff;
}

.horizondalLine {
    height: auto;
}

textarea.doctorWritenPlace {
    width: 100%;
    height: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 20px;
}
form.caseSheetTextAreas {
    width: 75%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:991px) {
    .RegisterPatientText {
        padding-top: 0;
    }
    .col-sm-12.usersStyle {
        margin: auto;
        background-color: #ffff;
        border-radius: none;
        padding-bottom: 15px;
        box-shadow: none;
    }

    .nav.nav-tabs {
        padding-top: 0px;
    }

    .left-space-desktop {
        margin-left: 0px;
    }
}

@media screen and (max-width:769px) {
    form.caseSheetTextAreas {
        width: 100%;
    }

}

@media screen and (max-width:576px) {
    .text-center.name-fix {
        font-size: 15px;
    }
}
</style>