<template>
  <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
      <nav class="nav nav-fill bottom-nav text-align text-center">
        <router-link to="/clinicianothers/index" class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/clinicianothers/index') }" aria-current="page">
          <font-awesome-icon :icon="['fas', 'home']" />
          <p class="bottom-title">Home</p>
        </router-link>

        <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/clinicianothers/consultationdesk') }" @click="getClinicanAccess('consultation')">
          <font-awesome-icon :icon="['fas', 'briefcase-medical']" />
          <p class="bottom-title">Consultation</p>
        </div>

        <router-link to="/clinicianothers/patient-groups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/clinicianothers/patient-groups') }">
          <font-awesome-icon :icon="['fas', 'user-friends']" />
          <p class="bottom-title">Groups</p>
        </router-link> 
<!-- 
        <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/clinicianothers/library') }" @click="getClinicanAccess('library')">
          <font-awesome-icon :icon="['fas', 'book-medical']" />
          <p class="bottom-title">Library</p>
        </div>  -->

        <div v-if="clinicianOthers.updated_by == 'admin-customer' && clinicianOthers.customer"  class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/clinicianothers/manage') }" @click="getClinicanAccess('manage')">
          <img :src="clinicianOthers?.customer?.logo" alt="Customer Logo" style="width: 50px; height: 50px;" class="favicon-class" />
          <p class="bottom-title">Manage</p>
        </div>

        <!-- More button -->
        <div class="nav-link bottom-link footer-image-aftersignup" @click="toggleMoreMenu">
          <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
          <p class="bottom-title">More</p>
        </div>
      </nav>

      <!-- Additional items that are shown when 'More' is clicked -->
      <nav v-if="showMoreMenu" class="nav nav-fill  text-align text-center additional-items">
        <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/clinicianothers/library') }" @click="getClinicanAccess('library')">
          <font-awesome-icon :icon="['fas', 'book-medical']" />
          <p class="bottom-title">Library</p>
        </div> 

        <div class="nav-link bottom-link footer-image-aftersignup-report" :class="{ 'tab-activated': isRouteActive('/doctor/report') }" @click="getClinicanAccess('report')">
          <font-awesome-icon :icon="['fas', 'file-signature']" />
          <p class="bottom-title">Reports</p>
        </div>

        
      </nav>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
export default {
   data() {
   return {
    clinicianOthers:{},
    showMoreMenu: false,   }},
   created: function () {
    const clinicianID = localStorage.getItem('clinician-others-id')
     this.clinicianOthersID = JSON.parse(clinicianID);

    if(this.clinicianOthersID){
      this.getclinicianOthers();
     } },
   methods: {
    toggleMoreMenu() {
      this.showMoreMenu = !this.showMoreMenu;
    },
 currentPath(routePath) {
     this.$router.push(routePath);
   },
   isRouteActive(routePath) {
     return this.$route.path === routePath;
   },
   async getclinicianOthers(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
        .then((response) => {
          this.clinicianOthers = response.data
        });
    },
   getClinicanAccess(getValues){
      this.getclinicianOthers();
      console.log(this.clinicianOthers,"this.clinicianOthers");
      if(!this.clinicianOthers.speciality){
            Swal.fire({
              title: "Please complete the About Me section of the Settings Page",
              // icon:'question',
              confirmButtonColor: "#7066e0",
              confirmButtonText: "OK",
              }).then((result) => {
            if (result.isConfirmed) {
            const query = this.$route.query;
            window.scrollTo(0, 0);
            this.$router.push({ path: `/clinicianothers/mobile-settings`, query });
             }
            });
          }else if(this.clinicianOthers.speciality){
            if(getValues == 'library'){
              console.log("d");
              window.scrollTo(0, 0);
              console.log("?????");
              this.$router.push("/clinicianothers/library");
            }else if(getValues == 'consultation'){
              window.scrollTo(0, 0);
              this.$router.push("/clinicianothers/consultationdesk");
            }
            else if(getValues == 'manage'){
            window.scrollTo(0, 0);
            this.$router.push("/clinicianothers/manage");
            }
          }
    },
   }
}
</script>
<style scoped>
.additional-items {
  display: grid;
    position: absolute;
    bottom: 84px;
    right: 0;
}
.footer-image-aftersignup-report{
  margin: 0px !important;
 color:gray !important;
 /* cursor: pointer; */
 padding: 7px 14px;
}
.footer-image-aftersignup{
 margin: 0px !important;
 color: #000 !important;
 cursor: pointer;
 padding: 7px 14px;
}
.tab-activated {
 background-color:#00979e;
 color: #fff !important;
 margin: 0px;
}
</style>